export default [{
    question: 'Did you guys get the band back together?',
    answer: 'No.'
}, {
    question: 'Are you going to get the band back together?',
    answer: 'No.'
}, {
    question: 'Do you guys have any shows?',
    answer: 'No.'
}, {
    question: 'Are you ever going to play another show?',
    answer: 'No.'
}, {
    question: 'Why are you recording a new album if you\'re still broken up and you\'re not going to play any more shows?',
    answer: 'Why indeed.'
}, {
    question: 'How much more black can this background color be?',
    answer: 'None. None more black.'
}, {
    question: 'Please get the band back together!',
    answer: 'Ok.'
}, {
    question: 'Really!?',
    answer: 'No.'
}, {
    question: 'Now that you\'ve released the new album, what\'s next?',
    answer: 'Nothing. Nothing\'s next. We\'ve broken up. You won\'t hear from us again.'
}];
