export default [{
    text: 'Home',
    href: '/'
}, {
    text: 'Albums',
    href: '/albums'
}, {
    text: 'Videos',
    href: '/videos'
}, {
    text: 'FAQ',
    href: '/faq'
}];
